import _ from 'lodash';
import classNames from 'classnames';
import { useEffect } from 'react';
import { useState } from 'react';
import arrow_up from '../../../../assets/img/Report/arrow_green_up.png';
import arrow_down from '../../../../assets/img/Report/arrow_red_down.png';
import pagination_left_active from '../../../../assets/img/Report/pagination_left_active.png';
import pagination_left_inactive from '../../../../assets/img/Report/pagination_left_inactive.png';
import pagination_right_active from '../../../../assets/img/Report/pagination_right_active.png';
import pagination_right_inactive from '../../../../assets/img/Report/pagination_right_inactive.png';
import './Table.scss';
import { t } from 'i18next';

const Table = ({ rows, setRows }) => {
    const [newRows, setNewRows] = useState(rows);
    const [currentRows, setCurrentRows] = useState([]);
    const [pages, setPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [startNum, setStartNum] = useState(0);
    const [endNum, setEndNum] = useState(20);
    const [sortBy, setSortBy] = useState('Full Name');

    const nextPage = () => {
        if (currentPage === pages - 1)
            return;
        setCurrentPage(value => value + 1);
    }
    const prevPage = () => {
        if (currentPage === 0)
            return;
        setCurrentPage(value => value - 1);
    }

    useEffect(() => {
        setCurrentPage(0);
        setPages(_.ceil(newRows.length / 20));
    }, [newRows]);
    useEffect(() => {
        const startnum = newRows.length !== 0 ? currentPage * 20 + 1 : 0;
        const expectedEndNum = currentPage * 20 + 20;
        const endnum = expectedEndNum <= newRows.length ? expectedEndNum : newRows.length;
        setStartNum(startnum);
        setEndNum(endnum);
    }, [currentPage, newRows]);
    useEffect(() => {
        setCurrentRows(newRows.slice(startNum - 1, endNum));
    }, [endNum, newRows, currentPage]);

    useEffect(() => {
        setNewRows(rows);
    }, [rows]);


    const sort = (value) => {
        console.log('sort by', value)
        if (sortBy === value) {
            const currRows = [...newRows];
            setNewRows(_.reverse(currRows));
            return;
        }
        setSortBy(value);
    }
    useEffect(() => {
        switch (sortBy) {
            case 'Date':
                setNewRows(currRows => _.sortBy(currRows, (a) => {
                    return a['Date'].split('.').reverse().join('.');
                }));
                return;
            default:
                setNewRows(currRows => _.sortBy(currRows, sortBy));
                return;
        }
    }, [sortBy, rows]);

    return (
        <>
            <div className='table-container scroll-style'>
                <table width={100} cellPadding={0} cellSpacing='0' border={0}>
                    <thead>
                        <tr className='font-20 no-select'>
                            <th
                                className='ta-center t-col-1 frsti'
                            >
                                {t('investors.report.logo')}
                            </th>
                            <th
                                className={`ta-left c-pointer t-col-2 ${sortBy === 'Full Name' ? 'sort-arrow-up' : 'sort-arrow-default'}`}
                                onClick={() => sort('Full Name')}
                            >
                                {t('investors.report.full_name')}
                            </th>
                            <th
                                className={`ta-left c-pointer t-col-3 ${sortBy === 'Code' ? 'sort-arrow-up' : 'sort-arrow-default'}`}
                                onClick={() => sort('Code')}

                            >
                                {t('investors.report.code')}
                            </th>
                            <th
                                className={`ta-right c-pointer t-col-4 ${sortBy === 'Date' ? 'sort-arrow-up' : 'sort-arrow-default'}`}
                                onClick={() => sort('Date')}
                            >
                                {t('investors.report.date')}
                            </th>
                            <th
                                className={`ta-right c-pointer t-col-5 pr-15 ${sortBy === 'Forecast' ? 'sort-arrow-up' : 'sort-arrow-default'}`}
                                onClick={() => sort('Forecast')}
                            >
                                {t('investors.report.forecast')}
                            </th>
                            <th
                                className={`ta-right c-pointer t-col-6 lsti ${sortBy === 'Previous period' ? 'sort-arrow-up' : 'sort-arrow-default'}`}
                                onClick={() => sort('Previous period')}
                            >
                                {t('investors.report.prev_period')}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentRows.map((row, index) => {
                            const forecastUp = row["Forecast"] >= row["Previous period"] ? true : false;
                            const forecastClasses = classNames({
                                'column-color-green': forecastUp === true,
                                'column-color-red': forecastUp === false,
                            });
                            return (
                                <tr key={index}>
                                    <td className='ta-center t-col-1 frsti'>
                                        <img className='tblogo' src={`/assets/table-logos/${_.toLower(row["Code"])}.webp`} alt='' />
                                    </td>
                                    <td className='ta-left t-col-2'>{row["Full Name"]}</td>
                                    <td className='ta-left t-col-3'>{row["Code"]}</td>
                                    <td className='ta-right t-col-4'>{row["Date"]}</td>
                                    <td
                                        className={`ta-right t-col-5 ${forecastClasses}`}
                                    >
                                        <div className='frcst'>
                                            {row["Forecast"]}
                                            <img className='column-arrow' src={forecastUp ? arrow_up : arrow_down} alt='' />
                                        </div>
                                    </td>
                                    <td className='ta-right t-col-6 lsti'>{row["Previous period"]}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>

            <div className='pagination'>
                <div className='pagination-text'>
                    {startNum}-{endNum} of {newRows.length}
                </div>
                <div
                    onClick={prevPage}
                    className='pagination-arrow arrow-left c-pointer'
                >
                    <img src={currentPage === 0 ? pagination_left_inactive : pagination_left_active} alt='' />
                </div>
                <div
                    onClick={nextPage}
                    className='pagination-arrow arrow-right c-pointer'
                >
                    <img src={currentPage === pages - 1 ? pagination_right_inactive : pagination_right_active} alt='' />
                </div>
            </div>
        </>
    );
};

export default Table;
