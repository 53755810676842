import React, { useEffect } from "react";

import Main from '../components/ForInvestors/Main'
import Quarte from "../components/ForInvestors/Quarte";
import Ready from "../components/ForInvestors/Ready";
import Report from "../components/ForInvestors/Report/Report";
import Form from "../components/ForInvestors/Form/Form";

function ForInvestors () {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      
    return(
        <div className="pt-[80px]">
            <Main/>
            <Quarte/>
            <Ready/>
            <Report/>
            <Form/>
        </div>
    );
}

export default ForInvestors;