import { useTranslation } from 'react-i18next';
import Dropdown from 'react-dropdown';
import './Search.scss';

const regions = [
    { value: 'all', label: 'All' },
    { value: 'stocks-american', label: 'Stocks-American' },
    { value: 'stocks-arabic', label: 'Stocks-Arabic' },
    { value: 'stocks-european', label: 'Stocks-European' },
    { value: 'stocks-russian', label: 'Stocks-Russian' },
    { value: 'stocks-asian', label: 'Stocks-Asian' },
];
const defaultOption = regions[0].value;

const Search = ({ nameFilter, setNameFilter, setRegionFilter, setAllFilter }) => {
    const { t } = useTranslation();
    return (
        <div className='search-container'>
            <div className='search-container-search'>
                <div className='search-container-search-text search-col font-48'>
                    {t('investors.report.title')}
                </div>
                <div className='search-container-search-input search-col'>
                    <input
                        type='text'
                        placeholder={t('investors.report.search')}
                        value={nameFilter}
                        onChange={(event) => setNameFilter(event.target.value)}
                    />
                    <Dropdown
                        className='dropdown-component'
                        placeholderClassName='dropdown' 
                        options={regions} 
                        onChange={(event) => setRegionFilter(event.value)} 
                        value={defaultOption} 
                    />
                </div>
            </div>
        </div>
    );
};

export default Search;
