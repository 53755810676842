import React from "react";

import { useTranslation, Trans } from "react-i18next";

import main from '../../img/ForInvestors/main2.png'

function Main () {

      const { t } = useTranslation();
    return(
        <div className="max-w-[1280px] mx-[20px] xl:mx-auto">
            <div className="h-[283px] relative">
                <div className="absolute left-[40px] top-[40px]">
                    <p className="text-white text-[25px] xl:text-[40px] manrope font-[500] leading-[1.5]"><Trans>{t('investors.ipo.1')}</Trans></p>
                    <p className="mt-[15px] roboto text-white font-[300] leading-[1.5] xl:max-w-[472px]">{t('investors.ipo.2')}</p>
                </div>
                <img src={main} alt="" className="w-full h-full object-cover"/>
            </div>
        </div>
    );
}

export default Main;