import React from "react";

import { useTranslation, Trans } from "react-i18next";

function AccessRange () {

    const { t } = useTranslation();

    return(
        <div className="max-w-[1280px] mx-[20px] xl:mx-auto flex flex-col mt-[80px] xl:mt-[160px]">
            <div className="flex flex-col xl:flex-row justify-between">
                <div className="xl:max-w-[500px]">
                    <p className="mx-auto manrope text-[#001F61] text-[20px] xl:text-[30px] font-[500]"><Trans>{t('investors.ipo.10')}</Trans></p>
                    <p className="text-[#000000B2] roboto font-[300] mt-[30px]">{t('investors.ipo.11')}</p>
                    <p className="text-[#000000B2] roboto font-[300] mt-[15px]">{t('investors.ipo.12')}</p>
                    <p className="text-[#000000B2] roboto font-[300] mt-[15px]">{t('investors.ipo.13')}</p>
                    <p className="text-[#000000B2] roboto font-[300] mt-[15px]">{t('investors.ipo.14')}</p>
                </div>
                <div className="xl:max-w-[500px] mt-[20px] xl:mt-0">
                    <p className="mx-auto manrope text-[#001F61] text-[20px] xl:text-[30px] font-[500]"><Trans>{t('investors.ipo.15')}</Trans></p>
                    <p className="text-[#000000B2] roboto font-[300] mt-[30px]">{t('investors.ipo.16')}</p>
                    <p className="text-[#000000B2] roboto font-[300] mt-[15px]">{t('investors.ipo.17')}</p>
                </div>
            </div>
        </div>
    );
}

export default AccessRange;