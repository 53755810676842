import React, { useEffect } from "react";

import Main from '../components/ForInvestors2/Main'
import OurServices from "../components/ForInvestors2/OurServices";
import AccessRange from "../components/ForInvestors2/AccessRange";
import SetUp from "../components/ForInvestors2/SetUp";
import Faqs from "../components/ForInvestors2/Faqs";

function ForInvestors2 () {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      
    return(
        <div className="pt-[80px]">
            <Main/>
            <OurServices/>
            <AccessRange/>
            <SetUp/>
            <Faqs/>
        </div>
    );
}

export default ForInvestors2;