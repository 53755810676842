import React from "react";
import classes from './Input.module.scss';
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useTranslation } from "react-i18next";

const Phone = ({
    setValue,
    valid = true,
    label,
}) => {
    const { t } = useTranslation();
    return (
        <div className={classes.inputField}>
            <p className={classes.label}>
                {t(label)}
            </p>
            <PhoneInput
                country={"gb"}
                // preferredCountries={["ua"]}
                onChange={(phone) => setValue(phone)}
                className={`${classes.phone}`}
                containerClass={classes.containerClass}
                inputClass={`${classes.inputClass} ${!valid ? classes.error : ''}`}
                buttonClass={classes.buttonClass}
                dropdownClass={classes.dropdownClass}
                searchClass={classes.searchClass}
            />
        </div>
    );
};

export default Phone;
