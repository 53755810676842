const table = [
    {
        "Logo": "abbv.webp",
        "Full Name": "Abbvie Inc",
        "Code": "ABBV",
        "Date": "30.10.2024",
        "Forecast": 2.93,
        "Previous period": 2.95,
        "Region": "stocks-american"
    },
    {
        "Logo": "ibe.webp",
        "Full Name": "Acciones Iberdrola",
        "Code": "IBE",
        "Date": "23.10.2024",
        "Forecast": 0.16,
        "Previous period": 0.17,
        "Region": "stocks-european"
    },
    {
        "Logo": "adds.webp",
        "Full Name": "Adidas",
        "Code": "ADDS",
        "Date": "29.10.2024",
        "Forecast": 1.82,
        "Previous period": 1.4,
        "Region": "stocks-european"
    },
    {
        "Logo": "adbe.webp",
        "Full Name": "Adobe",
        "Code": "ADBE",
        "Date": "11.12.2024",
        "Forecast": 4.66,
        "Previous period": 4.27,
        "Region": "stocks-american"
    },
    {
        "Logo": "amd.webp",
        "Full Name": "Advanced Micro Devices",
        "Code": "AMD",
        "Date": "29.10.2024",
        "Forecast": 0.92,
        "Previous period": 0.7,
        "Region": "stocks-american"
    },
    {
        "Logo": "air.webp",
        "Full Name": "Airbus",
        "Code": "AIR",
        "Date": "30.10.2024",
        "Forecast": 1.05,
        "Previous period": 1.02,
        "Region": "stocks-european"
    },
    {
        "Logo": "1120.webp",
        "Full Name": "Al-Rajhi Bank",
        "Code": "1120",
        "Date": "21.10.2024",
        "Forecast": 1.14,
        "Previous period": 0.99,
        "Region": "stocks-arabic"
    },
    {
        "Logo": "baba.webp",
        "Full Name": "Alibaba",
        "Code": "BABA",
        "Date": "14.11.2024",
        "Forecast": 2.1,
        "Previous period": 2.14,
        "Region": "stocks-american"
    },
    {
        "Logo": "1150.webp",
        "Full Name": "Alinma Bank",
        "Code": "1150",
        "Date": "22.10.2024",
        "Forecast": 0.54,
        "Previous period": 0.51,
        "Region": "stocks-arabic"
    },
    {
        "Logo": "goog.webp",
        "Full Name": "Alphabet Inc (Google) Class C",
        "Code": "GOOG",
        "Date": "29.10.2024",
        "Forecast": 1.83,
        "Previous period": 1.55,
        "Region": "stocks-american"
    },
    {
        "Logo": "ams.webp",
        "Full Name": "Amadeus IT Group, S.A",
        "Code": "AMS",
        "Date": "07.11.2024",
        "Forecast": 0.75,
        "Previous period": 0.71,
        "Region": "stocks-european"
    },
    {
        "Logo": "amzn.webp",
        "Full Name": "Amazon",
        "Code": "AMZN",
        "Date": "31.10.2024",
        "Forecast": 1.13,
        "Previous period": 0.94,
        "Region": "stocks-american"
    },
    {
        "Logo": "axp.webp",
        "Full Name": "American Express",
        "Code": "AXP",
        "Date": "24.01.2025",
        "Forecast": 2.98,
        "Previous period": 2.62,
        "Region": "stocks-american"
    },
    {
        "Logo": "aout.webp",
        "Full Name": "American Outdoor Brands",
        "Code": "AOUT",
        "Date": "29.11.2024",
        "Forecast": 0.2,
        "Previous period": 0.25,
        "Region": "stocks-american"
    },
    {
        "Logo": "amgn.webp",
        "Full Name": "Amgen Inc",
        "Code": "AMGN",
        "Date": "30.10.2024",
        "Forecast": 5.1,
        "Previous period": 4.96,
        "Region": "stocks-american"
    },
    {
        "Logo": "ang.webp",
        "Full Name": "Anglogold Ashanti ltd",
        "Code": "ANG",
        "Date": "02.08.2024",
        "Forecast": 28.45,
        "Previous period": 22.34,
        "Region": "stocks-american"
    },
    {
        "Logo": "aapl.webp",
        "Full Name": "Apple",
        "Code": "AAPL",
        "Date": "31.10.2024",
        "Forecast": 1.59,
        "Previous period": 1.46,
        "Region": "stocks-american"
    },
    {
        "Logo": "1080.webp",
        "Full Name": "Arab National Bank",
        "Code": "1080",
        "Date": "21.10.2024",
        "Forecast": 0.5,
        "Previous period": 0.54,
        "Region": "stocks-arabic"
    },
    {
        "Logo": "abr.webp",
        "Full Name": "Arbor Realty Trust Inc",
        "Code": "ABR",
        "Date": "25.10.2024",
        "Forecast": 0.39,
        "Previous period": 0.55,
        "Region": "stocks-american"
    },
    {
        "Logo": "azn.webp",
        "Full Name": "AstraZeneca plc",
        "Code": "AZN",
        "Date": "12.11.2024",
        "Forecast": 1.02,
        "Previous period": 0.86,
        "Region": "stocks-american"
    },
    {
        "Logo": "acb.webp",
        "Full Name": "Aurora Cannabis Inc",
        "Code": "ACB",
        "Date": "07.11.2024",
        "Forecast": -0.08,
        "Previous period": 0.0,
        "Region": "stocks-american"
    },
    {
        "Logo": "bbva.webp",
        "Full Name": "Banco Bilbao Vizcaya Argentaria, S.A",
        "Code": "BBVA",
        "Date": "31.10.2024",
        "Forecast": 0.4,
        "Previous period": 0.33,
        "Region": "stocks-european"
    },
    {
        "Logo": "san.webp",
        "Full Name": "Banco Santander S.A",
        "Code": "SAN",
        "Date": "29.10.2024",
        "Forecast": 0.19,
        "Previous period": 0.17,
        "Region": "stocks-american"
    },
    {
        "Logo": "1050.webp",
        "Full Name": "Banque Saudi Fransi",
        "Code": "1050",
        "Date": "23.10.2024",
        "Forecast": 0.9,
        "Previous period": 0.98,
        "Region": "stocks-arabic"
    },
    {
        "Logo": "barc.webp",
        "Full Name": "Barclays plc ord",
        "Code": "BARC",
        "Date": "24.10.2024",
        "Forecast": 8.51,
        "Previous period": 8.3,
        "Region": "stocks-european"
    },
    {
        "Logo": "gold.webp",
        "Full Name": "Barrick Gold Corporation",
        "Code": "GOLD",
        "Date": "07.11.2024",
        "Forecast": 0.31,
        "Previous period": 0.24,
        "Region": "stocks-american"
    },
    {
        "Logo": "bmw.webp",
        "Full Name": "Bay.motoren Werke AG ST",
        "Code": "BMW",
        "Date": "06.11.2024",
        "Forecast": 1.33,
        "Previous period": 4.2,
        "Region": "stocks-european"
    },
    {
        "Logo": "bynd.webp",
        "Full Name": "Beyond Meat Inc",
        "Code": "BYND",
        "Date": "06.11.2024",
        "Forecast": -0.47,
        "Previous period": -1.09,
        "Region": "stocks-american"
    },
    {
        "Logo": "bhp.webp",
        "Full Name": "BHP group ltd",
        "Code": "BHP",
        "Date": "14.02.2025",
        "Forecast": 2.38,
        "Previous period": 1.33,
        "Region": "stocks-european"
    },
    {
        "Logo": "ba.webp",
        "Full Name": "Boeing CO",
        "Code": "BA",
        "Date": "23.10.2024",
        "Forecast": -10.35,
        "Previous period": -3.26,
        "Region": "stocks-american"
    },
    {
        "Logo": "bkng.webp",
        "Full Name": "Booking Holding INC",
        "Code": "BKNG",
        "Date": "30.10.2024",
        "Forecast": 76.99,
        "Previous period": 72.32,
        "Region": "stocks-american"
    },
    {
        "Logo": "cgc.webp",
        "Full Name": "Canopy Growth Corporation",
        "Code": "CGC",
        "Date": "12.11.2024",
        "Forecast": -0.36,
        "Previous period": -3.16,
        "Region": "stocks-american"
    },
    {
        "Logo": "ccl.webp",
        "Full Name": "Carnival Corp",
        "Code": "CCL",
        "Date": "19.12.2024",
        "Forecast": 0.06,
        "Previous period": -0.07,
        "Region": "stocks-american"
    },
    {
        "Logo": "gtls.webp",
        "Full Name": "Chart Industries Inc",
        "Code": "GTLS",
        "Date": "25.10.2024",
        "Forecast": 2.5,
        "Previous period": 1.28,
        "Region": "stocks-american"
    },
    {
        "Logo": "cvx.webp",
        "Full Name": "Chevron Corporation",
        "Code": "CVX",
        "Date": "01.11.2024",
        "Forecast": 2.56,
        "Previous period": 3.05,
        "Region": "stocks-american"
    },
    {
        "Logo": "601390.webp",
        "Full Name": "China Railway Group Limited",
        "Code": "601390",
        "Date": "31.10.2024",
        "Forecast": 0.37,
        "Previous period": 0.3,
        "Region": "stocks-asian"
    },
    {
        "Logo": "c.webp",
        "Full Name": "Citigroup Inc",
        "Code": "C",
        "Date": "15.01.2025",
        "Forecast": 1.2,
        "Previous period": -1.16,
        "Region": "stocks-american"
    },
    {
        "Logo": "ko.webp",
        "Full Name": "CocaCola",
        "Code": "KO",
        "Date": "23.10.2024",
        "Forecast": 0.74,
        "Previous period": 0.74,
        "Region": "stocks-american"
    },
    {
        "Logo": "crwd.webp",
        "Full Name": "CrowdStrike Holdings Inc",
        "Code": "CRWD",
        "Date": "26.11.2024",
        "Forecast": 0.8,
        "Previous period": 0.82,
        "Region": "stocks-american"
    },
    {
        "Logo": "4300.webp",
        "Full Name": "Dar Al Arkan Real Estate",
        "Code": "4300",
        "Date": "04.11.2024",
        "Forecast": 0.23,
        "Previous period": 0.14,
        "Region": "stocks-arabic"
    },
    {
        "Logo": "db.webp",
        "Full Name": "Deutsche Bank AG",
        "Code": "DB",
        "Date": "23.10.2024",
        "Forecast": 0.61,
        "Previous period": 0.59,
        "Region": "stocks-american"
    },
    {
        "Logo": "ebay.webp",
        "Full Name": "eBay",
        "Code": "EBAY",
        "Date": "30.10.2024",
        "Forecast": 1.17,
        "Previous period": 1.03,
        "Region": "stocks-american"
    },
    {
        "Logo": "xom.webp",
        "Full Name": "Exxon Mobil Corporation",
        "Code": "XOM",
        "Date": "01.11.2024",
        "Forecast": 1.89,
        "Previous period": 2.27,
        "Region": "stocks-american"
    },
    {
        "Logo": "fb.webp",
        "Full Name": "Facebook Inc",
        "Code": "FB",
        "Date": "30.10.2024",
        "Forecast": 5.2,
        "Previous period": 4.39,
        "Region": "stocks-american"
    },
    {
        "Logo": "fdx.webp",
        "Full Name": "FedEx Corp",
        "Code": "FDX",
        "Date": "19.12.2024",
        "Forecast": 4.09,
        "Previous period": 3.99,
        "Region": "stocks-american"
    },
    {
        "Logo": "race.webp",
        "Full Name": "Ferrari",
        "Code": "RACE",
        "Date": "05.11.2024",
        "Forecast": 2.22,
        "Previous period": 1.92,
        "Region": "stocks-american"
    },
    {
        "Logo": "f.webp",
        "Full Name": "Ford",
        "Code": "F",
        "Date": "28.10.2024",
        "Forecast": 0.46,
        "Previous period": 0.39,
        "Region": "stocks-american"
    },
    {
        "Logo": "ge.webp",
        "Full Name": "General Electric Co",
        "Code": "GE",
        "Date": "22.10.2024",
        "Forecast": 1.13,
        "Previous period": 0.65,
        "Region": "stocks-american"
    },
    {
        "Logo": "gild.webp",
        "Full Name": "Gilead Sciences Inc",
        "Code": "GILD",
        "Date": "06.11.2024",
        "Forecast": 1.54,
        "Previous period": 2.29,
        "Region": "stocks-american"
    },
    {
        "Logo": "gsk.webp",
        "Full Name": "Glaxosmithkline adr each",
        "Code": "GSK",
        "Date": "30.10.2024",
        "Forecast": 1.16,
        "Previous period": 1.22,
        "Region": "stocks-american"
    },
    {
        "Logo": "gs.webp",
        "Full Name": "Goldman Sach Group Inc",
        "Code": "GS",
        "Date": "16.01.2025",
        "Forecast": 8.06,
        "Previous period": 5.48,
        "Region": "stocks-american"
    },
    {
        "Logo": "gpro.webp",
        "Full Name": "GoPro Inc",
        "Code": "GPRO",
        "Date": "07.11.2024",
        "Forecast": -0.03,
        "Previous period": 0.04,
        "Region": "stocks-american"
    },
    {
        "Logo": "hog.webp",
        "Full Name": "HarleyDavidson",
        "Code": "HOG",
        "Date": "24.10.2024",
        "Forecast": 0.8,
        "Previous period": 1.38,
        "Region": "stocks-american"
    },
    {
        "Logo": "hlf.webp",
        "Full Name": "Herbalife Nutrition LTD",
        "Code": "HLF",
        "Date": "30.10.2024",
        "Forecast": 0.31,
        "Previous period": 0.65,
        "Region": "stocks-american"
    },
    {
        "Logo": "hon.webp",
        "Full Name": "Honeywell International Inc",
        "Code": "HON",
        "Date": "24.10.2024",
        "Forecast": 2.5,
        "Previous period": 2.27,
        "Region": "stocks-american"
    },
    {
        "Logo": "itx.webp",
        "Full Name": "Industria de dies\\o textil S.A. Inditex-",
        "Code": "ITX",
        "Date": "11.12.2024",
        "Forecast": 0.56,
        "Previous period": 0.51,
        "Region": "stocks-european"
    },
    {
        "Logo": "idcby.webp",
        "Full Name": "Industrial and Commercial Bank of China",
        "Code": "IDCBY",
        "Date": "31.10.2024",
        "Forecast": 0.71,
        "Previous period": 0.65,
        "Region": "stocks-asian"
    },
    {
        "Logo": "ino.webp",
        "Full Name": "Inovio Pharmaceuticals Inc",
        "Code": "INO",
        "Date": "05.11.2024",
        "Forecast": -1.15,
        "Previous period": -1.56,
        "Region": "stocks-american"
    },
    {
        "Logo": "intc.webp",
        "Full Name": "Intel Corp",
        "Code": "INTC",
        "Date": "31.10.2024",
        "Forecast": -0.02,
        "Previous period": 0.41,
        "Region": "stocks-american"
    },
    {
        "Logo": "ibm.webp",
        "Full Name": "International bus mach corp",
        "Code": "IBM",
        "Date": "23.10.2024",
        "Forecast": 2.22,
        "Previous period": 2.2,
        "Region": "stocks-american"
    },
    {
        "Logo": "jnj.webp",
        "Full Name": "Johnson & Johnson",
        "Code": "JNJ",
        "Date": "28.01.2025",
        "Forecast": 2.04,
        "Previous period": 2.29,
        "Region": "stocks-american"
    },
    {
        "Logo": "jpm.webp",
        "Full Name": "JPMorgan Chase & CO",
        "Code": "JPM",
        "Date": "15.01.2025",
        "Forecast": 3.86,
        "Previous period": 3.04,
        "Region": "stocks-american"
    },
    {
        "Logo": "lmt.webp",
        "Full Name": "Lockheed Martin Corp",
        "Code": "LMT",
        "Date": "22.10.2024",
        "Forecast": 6.5,
        "Previous period": 6.77,
        "Region": "stocks-american"
    },
    {
        "Logo": "mc.webp",
        "Full Name": "Louis Vuitton (LVMH)",
        "Code": "MC",
        "Date": "23.01.2025",
        "Forecast": 12.89,
        "Previous period": 13.74,
        "Region": "stocks-european"
    },
    {
        "Logo": "lyft.webp",
        "Full Name": "Lyft Inc",
        "Code": "LYFT",
        "Date": "06.11.2024",
        "Forecast": 0.17,
        "Previous period": 0.23,
        "Region": "stocks-american"
    },
    {
        "Logo": "manu.webp",
        "Full Name": "Manchester utd plc new",
        "Code": "MANU",
        "Date": "27.11.2024",
        "Forecast": -0.37,
        "Previous period": -0.07,
        "Region": "stocks-american"
    },
    {
        "Logo": "ma.webp",
        "Full Name": "Mastercard Incorporated",
        "Code": "MA",
        "Date": "31.10.2024",
        "Forecast": 3.73,
        "Previous period": 3.39,
        "Region": "stocks-american"
    },
    {
        "Logo": "mcd.webp",
        "Full Name": "McDonald's Corporation",
        "Code": "MCD",
        "Date": "29.10.2024",
        "Forecast": 3.19,
        "Previous period": 3.19,
        "Region": "stocks-american"
    },
    {
        "Logo": "med.webp",
        "Full Name": "Medifast Inc",
        "Code": "MED",
        "Date": "04.11.2024",
        "Forecast": -0.27,
        "Previous period": 2.12,
        "Region": "stocks-american"
    },
    {
        "Logo": "msft.webp",
        "Full Name": "Microsoft Corporation",
        "Code": "MSFT",
        "Date": "30.10.2024",
        "Forecast": 3.1,
        "Previous period": 2.99,
        "Region": "stocks-american"
    },
    {
        "Logo": "gmkn.webp",
        "Full Name": "MMC Norilsk Nickel",
        "Code": "GMKN",
        "Date": "28.08.2023",
        "Forecast": 1.64,
        "Previous period": 2.01,
        "Region": "stocks-russian"
    },
    {
        "Logo": "mrna.webp",
        "Full Name": "Moderna Inc.",
        "Code": "MRNA",
        "Date": "07.11.2024",
        "Forecast": -1.93,
        "Previous period": -9.53,
        "Region": "stocks-american"
    },
    {
        "Logo": "nfg.webp",
        "Full Name": "National Gas & Industrialization Company",
        "Code": "NFG",
        "Date": "06.11.2024",
        "Forecast": 0.79,
        "Previous period": 0.78,
        "Region": "stocks-arabic"
    },
    {
        "Logo": "nflx.webp",
        "Full Name": "Netflix",
        "Code": "NFLX",
        "Date": "16.01.2025",
        "Forecast": 4.16,
        "Previous period": 2.11,
        "Region": "stocks-american"
    },
    {
        "Logo": "nem.webp",
        "Full Name": "Newmont Mining Corp.",
        "Code": "NEM",
        "Date": "23.10.2024",
        "Forecast": 0.88,
        "Previous period": 0.36,
        "Region": "stocks-american"
    },
    {
        "Logo": "nke.webp",
        "Full Name": "Nike",
        "Code": "NKE",
        "Date": "19.12.2024",
        "Forecast": 0.64,
        "Previous period": 1.03,
        "Region": "stocks-american"
    },
    {
        "Logo": "nkla.webp",
        "Full Name": "Nikola Corporation",
        "Code": "NKLA",
        "Date": "31.10.2024",
        "Forecast": -2.23,
        "Previous period": -9.0,
        "Region": "stocks-american"
    },
    {
        "Logo": "nclh.webp",
        "Full Name": "Norwegian Cruise",
        "Code": "NCLH",
        "Date": "31.10.2024",
        "Forecast": 0.93,
        "Previous period": 0.76,
        "Region": "stocks-american"
    },
    {
        "Logo": "nvax.webp",
        "Full Name": "Novavax Inc",
        "Code": "NVAX",
        "Date": "06.11.2024",
        "Forecast": -0.82,
        "Previous period": -1.26,
        "Region": "stocks-american"
    },
    {
        "Logo": "nus.webp",
        "Full Name": "Nu Skin Enterprises Inc",
        "Code": "NUS",
        "Date": "07.11.2024",
        "Forecast": 0.2,
        "Previous period": -0.74,
        "Region": "stocks-american"
    },
    {
        "Logo": "nvda.webp",
        "Full Name": "Nvidia Corp",
        "Code": "NVDA",
        "Date": "14.11.2024",
        "Forecast": 0.74,
        "Previous period": 0.4,
        "Region": "stocks-american"
    },
    {
        "Logo": "okta.webp",
        "Full Name": "Okta, Inc",
        "Code": "Okta",
        "Date": "27.11.2024",
        "Forecast": 0.58,
        "Previous period": 0.44,
        "Region": "stocks-american"
    },
    {
        "Logo": "orcl.webp",
        "Full Name": "Oracle Corporation",
        "Code": "ORCL",
        "Date": "12.12.2024",
        "Forecast": 1.48,
        "Previous period": 1.34,
        "Region": "stocks-american"
    },
    {
        "Logo": "pypl.webp",
        "Full Name": "PayPal Holdings Inc",
        "Code": "PYPL",
        "Date": "29.10.2024",
        "Forecast": 1.07,
        "Previous period": 1.3,
        "Region": "stocks-american"
    },
    {
        "Logo": "601857.webp",
        "Full Name": "PetroChina Company Limited",
        "Code": "601857",
        "Date": "30.10.2024",
        "Forecast": 0.22,
        "Previous period": 0.25,
        "Region": "stocks-asian"
    },
    {
        "Logo": "pfe.webp",
        "Full Name": "Pfizer Inc",
        "Code": "PFE",
        "Date": "29.10.2024",
        "Forecast": 0.6,
        "Previous period": -0.17,
        "Region": "stocks-american"
    },
    {
        "Logo": "pm.webp",
        "Full Name": "Philip Morris International Inc",
        "Code": "PM",
        "Date": "22.10.2024",
        "Forecast": 1.82,
        "Previous period": 1.67,
        "Region": "stocks-american"
    },
    {
        "Logo": "pg.webp",
        "Full Name": "Procter & Gamble",
        "Code": "PG",
        "Date": "22.01.2025",
        "Forecast": 1.91,
        "Previous period": 1.84,
        "Region": "stocks-american"
    },
    {
        "Logo": "rno.webp",
        "Full Name": "Renault",
        "Code": "RNO",
        "Date": "13.02.2025",
        "Forecast": 2.74,
        "Previous period": 0.29,
        "Region": "stocks-european"
    },
    {
        "Logo": "rio.webp",
        "Full Name": "RioTinto",
        "Code": "RIO",
        "Date": "26.02.2025",
        "Forecast": 3.1,
        "Previous period": 3.72,
        "Region": "stocks-european"
    },
    {
        "Logo": "1010.webp",
        "Full Name": "RiyadBank",
        "Code": "1010",
        "Date": "21.10.2024",
        "Forecast": 0.67,
        "Previous period": 0.66,
        "Region": "stocks-arabic"
    },
    {
        "Logo": "rosn.webp",
        "Full Name": "Rosneft oil co",
        "Code": "ROSN",
        "Date": "15.11.2024",
        "Forecast": 71.81,
        "Previous period": 73.8,
        "Region": "stocks-russian"
    },
    {
        "Logo": "rcl.webp",
        "Full Name": "Royal Caribbean Group",
        "Code": "RCL",
        "Date": "29.10.2024",
        "Forecast": 5.01,
        "Previous period": 3.85,
        "Region": "stocks-american"
    },
    {
        "Logo": "crm.webp",
        "Full Name": "Salesforce.com Inc",
        "Code": "CRM",
        "Date": "27.11.2024",
        "Forecast": 2.44,
        "Previous period": 2.11,
        "Region": "stocks-american"
    },
    {
        "Logo": "2010.webp",
        "Full Name": "Saudi Basic Industries Corporation",
        "Code": "2010",
        "Date": "04.11.2024",
        "Forecast": 0.49,
        "Previous period": 0.18,
        "Region": "stocks-arabic"
    },
    {
        "Logo": "2350.webp",
        "Full Name": "Saudi Kayan",
        "Code": "2350",
        "Date": "21.10.2024",
        "Forecast": -0.18,
        "Previous period": -0.3,
        "Region": "stocks-arabic"
    },
    {
        "Logo": "smg.webp",
        "Full Name": "Scotts Miracle-Gro Company",
        "Code": "SMG",
        "Date": "06.11.2024",
        "Forecast": -1.94,
        "Previous period": -2.77,
        "Region": "stocks-american"
    },
    {
        "Logo": "ssti.webp",
        "Full Name": "ShotSpotter Inc",
        "Code": "SSTI",
        "Date": "05.11.2024",
        "Forecast": -0.07,
        "Previous period": -0.15,
        "Region": "stocks-american"
    },
    {
        "Logo": "sie.webp",
        "Full Name": "Siemens ag na O.N",
        "Code": "SIE",
        "Date": "14.11.2024",
        "Forecast": 2.44,
        "Previous period": 2.15,
        "Region": "stocks-european"
    },
    {
        "Logo": "snap.webp",
        "Full Name": "SNap Inc",
        "Code": "SNAP",
        "Date": "29.10.2024",
        "Forecast": -0.13,
        "Previous period": -0.23,
        "Region": "stocks-american"
    },
    {
        "Logo": "sony.webp",
        "Full Name": "Sony Corporation",
        "Code": "SONY",
        "Date": "08.11.2024",
        "Forecast": 0.27,
        "Previous period": 0.21,
        "Region": "stocks-american"
    },
    {
        "Logo": "scco.webp",
        "Full Name": "Southern Copper Corp.",
        "Code": "SCCO",
        "Date": "22.10.2024",
        "Forecast": 1.11,
        "Previous period": 0.78,
        "Region": "stocks-american"
    },
    {
        "Logo": "spot.webp",
        "Full Name": "Spotify Technology SA",
        "Code": "SPOT",
        "Date": "12.11.2024",
        "Forecast": 1.91,
        "Previous period": 0.34,
        "Region": "stocks-american"
    },
    {
        "Logo": "sq.webp",
        "Full Name": "Square Inc.",
        "Code": "SQ",
        "Date": "31.10.2024",
        "Forecast": 0.88,
        "Previous period": 0.55,
        "Region": "stocks-american"
    },
    {
        "Logo": "rgr.webp",
        "Full Name": "SturmRuger",
        "Code": "RGR",
        "Date": "30.10.2024",
        "Forecast": 0.56,
        "Previous period": 0.42,
        "Region": "stocks-american"
    },
    {
        "Logo": "tgt.webp",
        "Full Name": "Target Corp",
        "Code": "TGT",
        "Date": "13.11.2024",
        "Forecast": 2.29,
        "Previous period": 2.1,
        "Region": "stocks-american"
    },
    {
        "Logo": "tsla.webp",
        "Full Name": "Tesla Inc",
        "Code": "TSLA",
        "Date": "23.10.2024",
        "Forecast": 0.59,
        "Previous period": 0.66,
        "Region": "stocks-american"
    },
    {
        "Logo": "1060.webp",
        "Full Name": "The Saudi British Bank",
        "Code": "1060",
        "Date": "24.10.2024",
        "Forecast": 0.95,
        "Previous period": 0.89,
        "Region": "stocks-arabic"
    },
    {
        "Logo": "tm.webp",
        "Full Name": "Toyota Motor Corp.",
        "Code": "TM",
        "Date": "06.11.2024",
        "Forecast": 5.03,
        "Previous period": 6.26,
        "Region": "stocks-american"
    },
    {
        "Logo": "vz.webp",
        "Full Name": "Verizon",
        "Code": "VZ",
        "Date": "22.10.2024",
        "Forecast": 1.17,
        "Previous period": 1.22,
        "Region": "stocks-american"
    },
    {
        "Logo": "v.webp",
        "Full Name": "Visa Inc",
        "Code": "V",
        "Date": "29.10.2024",
        "Forecast": 2.58,
        "Previous period": 2.33,
        "Region": "stocks-american"
    },
    {
        "Logo": "vow3.webp",
        "Full Name": "Volkswagen ag vzo O.N",
        "Code": "VOW3",
        "Date": "30.10.2024",
        "Forecast": 3.85,
        "Previous period": 7.76,
        "Region": "stocks-european"
    },
    {
        "Logo": "wmt.webp",
        "Full Name": "Walmart Inc",
        "Code": "WMT",
        "Date": "19.11.2024",
        "Forecast": 0.52,
        "Previous period": 0.51,
        "Region": "stocks-american"
    },
    {
        "Logo": "dis.webp",
        "Full Name": "Walt Disney Company",
        "Code": "DIS",
        "Date": "14.11.2024",
        "Forecast": 1.1,
        "Previous period": 0.82,
        "Region": "stocks-american"
    },
    {
        "Logo": "wu.webp",
        "Full Name": "Western Union Company",
        "Code": "WU",
        "Date": "23.10.2024",
        "Forecast": 0.43,
        "Previous period": 0.43,
        "Region": "stocks-american"
    },
    {
        "Logo": "zm.webp",
        "Full Name": "Zoom Video Communications Inc",
        "Code": "ZM",
        "Date": "19.11.2024",
        "Forecast": 1.3,
        "Previous period": 1.29,
        "Region": "stocks-american"
    },
    {
        "Logo": "zs.webp",
        "Full Name": "Zscaler Inc",
        "Code": "ZS",
        "Date": "27.11.2024",
        "Forecast": 0.62,
        "Previous period": 0.67,
        "Region": "stocks-american"
    }
];

export default table;