import React from "react";

import { useTranslation, Trans } from "react-i18next";

import r1 from '../../img/ForInvestors/r1.png'
import r2 from '../../img/ForInvestors/r2.png'
import r3 from '../../img/ForInvestors/r3.png'

function OurServices () {

    const { t } = useTranslation();

    return(
        <div className="max-w-[1280px] mx-[20px] xl:mx-auto flex flex-col mt-[80px] xl:mt-[160px]">
            <p className="mx-auto manrope text-[#001F61] text-[20px] xl:text-[30px] font-[500]">{t('investors.ipo.3')}</p>
            <div className="flex flex-col xl:flex-row gap-[20px] mt-[20px] xl:mt-[50px]">
                <div className="h-[245px] flex-grow bg-[#D1DEF8] p-[30px]">
                    <img src={r1} alt="" />
                    <p className="mx-auto manrope text-[#001F61] text-[16px] xl:text-[20px] font-[500] mt-[20px]">{t('investors.ipo.4')}</p>
                    <p className="text-[#000000B2] roboto font-[300] mt-[10px] xl:max-w-[316px]">{t('investors.ipo.5')}</p>
                </div>
                <div className="h-[245px] flex-grow bg-[#D1DEF8] p-[30px]">
                    <img src={r2} alt="" />
                    <p className="mx-auto manrope text-[#001F61] text-[16px] xl:text-[20px] font-[500] mt-[20px]">{t('investors.ipo.6')}</p>
                    <p className="text-[#000000B2] roboto font-[300] mt-[10px] xl:max-w-[316px]">{t('investors.ipo.7')}</p>
                </div>
                <div className="h-[245px] flex-grow bg-[#D1DEF8] p-[30px]">
                    <img src={r3} alt="" />
                    <p className="mx-auto manrope text-[#001F61] text-[16px] xl:text-[20px] font-[500] mt-[20px]">{t('investors.ipo.8')}</p>
                    <p className="text-[#000000B2] roboto font-[300] mt-[10px] xl:max-w-[316px]">{t('investors.ipo.9')}</p>
                </div>
            </div>
        </div>
    );
}

export default OurServices;