import React from "react";
import classes from './Input.module.scss';
import { useTranslation } from "react-i18next";

const Input = ({
    value,
    setValue,
    valid = true,
    type,
    label,
    placeholder = "",
}) => {
    const { t } = useTranslation();
    return (
        <div className={classes.inputField}>
            <p className={classes.label}>
                {t(label)}
            </p>
            <input
                type={type}
                placeholder={placeholder}
                value={value}
                onChange={(e) => setValue(e.target.value)}
                className={`${classes.input} ${!valid ? classes.error : ''}`}
            />
        </div>
    );
};

export default Input;