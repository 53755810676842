import React, { useState } from "react";

import { useTranslation } from "react-i18next";

function Quarte () {

      const { t } = useTranslation();

    
    return(
        <div className="max-w-[1280px] mx-[20px] xl:mx-auto mt-[80px] xl:mt-[160px] flex flex-col items-center">
            <p className="text-[#001F61] text-[16px] xl:text-[30px] manrope font-[500]">{t('forinvestors.5')}</p>
            <p className="roboto text-[#000000B2] text-[16px] font-[300] mt-[15px] xl:mt-[30px] xl:max-w-[872px] leading-[1.5]">{t('forinvestors.6')}</p>
            <p className="roboto text-[#000000B2] open-sans text-[16px] font-[300] mt-[15px] xl:mt-[30px] xl:max-w-[872px] leading-[1.5]">{t('forinvestors.8')}</p>
        </div>
    );
}

export default Quarte;