import React from "react";

import { Link } from "react-router-dom";

import { useTranslation, Trans } from "react-i18next";

function Ready () {

      const { t } = useTranslation();

    return(
        <div className="mt-[45px] xl:mt-[90px] bg-[#D1DEF8] xl:h-[345px] p-[20px] xl:p-[40px] relative flex flex-col">
            <div className="mx-[20px] xl:mx-auto flex flex-col items-center">
                <p className="text-[#001F61] font-[500] text-[16px] xl:text-[30px] text-center leading-[2]"><Trans>{t('forinvestors.9')}</Trans></p>
                <p className="mt-[10px] text-[#000000B2] font-[300] leading-[1.5] roboto text-center">{t('forinvestors.10')}</p> 
                <Link to='https://user.cgl-limited.net'><div className="button bg-[#0349DA] mt-[40px] z-50 relative">
                        {t('main.5')}
                    </div></Link>
            </div>
           
        </div>
    );
}

export default Ready;