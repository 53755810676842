import React from "react";

import { useTranslation, Trans } from "react-i18next";

function SetUp () {

    const { t } = useTranslation();

    return(
        <div>
            <div className="mt-[80px] xl:mt-[160px] bg-[#D1DEF8] py-[40px] xl:py-[80px]">
                <div className="max-w-[1280px] mx-[20px] xl:mx-auto flex flex-col xl:flex-row justify-between gap-[20px]">
                    <div className="xl:max-w-[260px] flex flex-col items-center">
                        <p className="mx-auto manrope text-[#001F61] text-[40px] xl:text-[60px] font-[800]">1</p>
                        <p className="mx-auto manrope text-[#001F61] text-[16px] xl:text-[20px] font-[500] text-center"><Trans>{t('investors.ipo.18')}</Trans></p>
                        <p className="text-[#000000B2] roboto font-[300] mt-[10px] text-center">{t('investors.ipo.19')}</p>
                    </div>
                    <div className="xl:max-w-[260px] flex flex-col items-center">
                        <p className="mx-auto manrope text-[#001F61] text-[40px] xl:text-[60px] font-[800]">2</p>
                        <p className="mx-auto manrope text-[#001F61] text-[16px] xl:text-[20px] font-[500] text-center"><Trans>{t('investors.ipo.20')}</Trans></p>
                        <p className="text-[#000000B2] roboto font-[300] mt-[10px] text-center">{t('investors.ipo.21')}</p>
                    </div>
                    <div className="xl:max-w-[260px] flex flex-col items-center">
                        <p className="mx-auto manrope text-[#001F61] text-[40px] xl:text-[60px] font-[800]">3</p>
                        <p className="mx-auto manrope text-[#001F61] text-[16px] xl:text-[20px] font-[500] text-center"><Trans>{t('investors.ipo.22')}</Trans></p>
                        <p className="text-[#000000B2] roboto font-[300] mt-[10px] text-center">{t('investors.ipo.23')}</p>
                    </div>
                </div>
            </div>
            <div className="max-w-[1280px] flex justify-end mx-[20px] xl:mx-auto mt-[6px]">
                <p className="md:max-w-[517px] text-[12px] roboto text-[#000000B2] font-[300]">{t('investors.ipo.24')}</p>
            </div>
        </div>
    );
}

export default SetUp;